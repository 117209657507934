import React, { FC, ReactElement } from 'react'
import Layout from '../components/layout'
import Container from '../components/container'

const MusicPage: FC = (): ReactElement => (
  <Layout
    title="Star Kitchen - Music"
    canonical="/music/"
    currentNav="Music"
    showNav={true}
  >
    <Container>
      <p>
        TODO
      </p>
    </Container>
  </Layout>
)

export default MusicPage
